
    export const VENUE_UUID = "2e4791c1-bf94-4d4d-98e3-4964d80caf5c"
    export const ORG_NAME = "rutheckerdhall"
    export const BASE_URL = "https://stadium.prd.crux.vnops.net/ordering_api/v1"
    export const BASE_LOYALTY_URL = "https://loyalty.prd.crux.vnops.net"
    export const PAULIE_URL = "https://paulie.prd.crux.vnops.net/vnapi/v1"
    export const BASE_USER_URL = "https://user.prd.crux.vnops.net"
    export const PRIVACY_POLICY_URL = "https://www.rutheckerdhall.com/"
    export const BRAINTREE_TOKEN = "production_hc3drwc3_8p9hhdtph2bjqyr6"
    export const HIDE_MERCH_STANDS = false
    export const SHOW_APPLE_PAY = true
    export const SHOW_GOOGLE_PAY = true
    export const ORG_ENV = "prd"
    export const USE_VN_API = true
    export const LOCAL_STORAGE_BUSTER_VERSION = "1.55.2"
    export const INSTANCE_NAME = "rutheckerdhall"
    export const API_GLOBAL_ENDPOINT = "https://venuenext.prd.api.vnops.net"
    export const API_ENDPOINT = "https://rutheckerdhall.principal.prd.api.vnops.net"
    export const SCANDIT_KEY = "ATBv5ISBECNnDmdj0QAFdE0bfXc2EEeat3DDjqhaetUjKEC+ChSZKsxpeVIHU7ywd3/zJz1JUHnlb/goJn9wj6ZYQpHjETH88kg+9pxZ185WXKJJSUDnXv9u1MYOZxkogXCbWHR7gjxmENbHqRCJB+M2swcSAmdy2IgIkQxIpoV81o9FVO8MQhaUAl/VGmSGIoBIjn8yY1VI+zbWE1VhGy70aR3J0KYeGbilzE1uSlLdv1Hs3fQu5H31tv0BgB3ko1x+vlRUGiJpwdhBzmiPupWMBmjZOT3Qv9lfEQ55h60k7NQVxPLBydfBb5V6IhBS9YneRmsLEVEcnPmmc73W+8pA9VlKs+V7TK6LT12wWrYD8OT9bW02XK5101CziJ2rCnDl8s3Uim/0lLqRh/ylK4cYlXowJe0h+mqAATsKYaLyEvkAtjFN5Wh0pFJwCbYRVSLBjbcMlsg+aHVBUoaiu7l3WhB0sJicH2WyJX+Wbs7YtU/yZQNFLEcmEXxH3MvXTAdp1PfuY7NQod4uu56lrqvRVmXch2lmhuIIf9jQ0EoxEpcVl8Xc8BfgPeXoS8OrRG0vyEnwq+5XXiYybc+9xObT6/7xlm9sVOpvE0F0vK+sgok6djpJc7c8Mo46Me8XndzleAWrvosUqTYjkgK8P1vhNwvCcBuz97KS3SU6K6x7TUDJTFB2RuqU+t47IkAdH/rCg+mBFFtWt+3XnUBjWWr1QOZix8NROXBI548jNOneNQXx2hJcH+6XZpLeiBJ2MlMlAO4Ff6t0h4dMQmRl4Lw7/4DQcJnzjqH99dKbdo6yJB6eXtjHD8k5HwW8f2C02v1XpN4A"
  